<template>
    <div>
		<el-table stripe border v-loading="loading" :data="dataList" >
			<el-table-column label="员工昵称" prop="nickname" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column label="员工名称" prop="loginName" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column label="员工编号" prop="userId" min-width="120" :show-overflow-tooltip="true"/>
			<el-table-column label="开通日期" prop="createTime" min-width="180" :show-overflow-tooltip="true"/>
			<el-table-column label="员工状态" prop="status" min-width="120" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<el-switch v-model="scope.row.state" active-value="ENABLE" inactive-value="DISABLE"
							   @change="handleStatusChange(scope.row)"/>
				</template>
			</el-table-column>
			

		</el-table>

		<Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>

		<CustomForm :dicts="dicts"
					:on-fresh="showCustomForm"
					:isAdd="isAdd"
					:selectRow="selectRow"
					@on-close="() => showCustomForm = false" ></CustomForm>
	</div>
</template>

<script>
	import {UserApi} from '@/api';
	import CustomForm from "./CustomForm";
	export default {
		name: "ToolBar",
		components: {CustomForm},
		props: {
			dicts: {
				type: Object,
				default: {}
			},
			queryParams: {
				type: Object,
				default: {},
			},
		},
		data() {
			return {
				pageNo: 1,
				pageSize: 10,
				loading: false,
				total: 0,
				dataList: [],
				selectRow: {},
				showCustomForm: false,
				isAdd: false
			}
		},
		mounted() {
		},
		watch: {
			queryParams: function () {
				this.pageNo = 1;
				this.getList();
			},
		},
		methods: {
			async getList() {
				this.loading = true;
				let result = await UserApi.user.list(this.pageNo, this.pageSize, this.queryParams);
				this.loading = false;
				this.total = result.count || 0;
				this.dataList = result.data || [];
			},
			handleStatusChange(row) {
				let text = row.status === "DISABLE" ? "FROM.DISABLE" : "FROM.ENABLE";
				this.$confirm(this.translate('FROM.CONFIRM_OPERATION', {module: row.nickname, operation: text}),
					this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					let result = await UserApi.user.edit(row.userId, {
						state: row.state
					});
					if (result.success) {
						this.getList();
					}
				}).catch(() => this.getList());
			},
			async handleUpdate(row) {
				this.selectRow = (await UserApi.user.detail(row.userId)).data || {};
				this.showCustomForm = true;
				this.isAdd = false;
			},
			handleDelete(row){
				this.$confirm(this.translate('FROM.CONFIRM_DELETE', {module: 'MODULE.USER', id: row.userId}), this.translate('FROM.WARNING'), {
					confirmButtonText: this.translate('FROM.SUBMIT'),
					cancelButtonText: this.translate('FROM.CANCEL'),
					type: "warning"
				}).then(async () => {
					let result = await UserApi.user.remove(row.userId);
					if (result.success) {
						this.Message.success(this.translate('FROM.DELETE_SUCCESS'));
						this.getList();
					}
				});
			},
			handleToUserRole(row){
				this.$router.push(`/userAuthority/userRoleManage/${row.userId}`)
			}

		}
	};
</script>

<style scoped>

</style>
